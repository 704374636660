export const state = {
    isPlaying: false,
    isCompleted: false,
    qtyCard: null,
    qtyCharacter: null,
    guess: null,
    game: null,
    tries: [],
    possibilities: [],
    dictionary: [],
    qtyCharacterOptions: [],
}
<template>
  <div class="row p-0 w-100">
    <div class="col-2 text-center px-2">
      <!-- <AdSense adClient="ca-pub-XXXXXXXXXXXXXXX" adSlot="XXXXXXXXX" /> -->
    </div>
    <div class="col-8 text-center px-2">
      <Header />
      <router-view />
    </div>
    <div class="col-2 text-center px-2">
      <!-- <AdSense adClient="ca-pub-XXXXXXXXXXXXXXX" adSlot="XXXXXXXXX" /> -->
    </div>
  </div>
</template>

<script>
import dictionary from "@/data/dictionary-ptbr.json";
import Header from "./components/Header.vue";
import { commonComputed } from "@/store/games/common";
import { watchEffect } from "vue";
import { useRoute } from "vue-router";
// // import AdSense from './components/AdSense.vue';

export default {
  name: "App",
  setup() {
    const route = useRoute();
    watchEffect(() => {
      document.title = route.meta.title || "Guessing Words";
    });
  },
  components: {
    Header, 
    // AdSense
   },
  beforeMount() {
    this.$store.dispatch("setDictionary", dictionary);
    let qtyCharacter = [];
    for (let qty = 1; qty <= 20; qty++) {
      if (dictionary[qty] != undefined) {
        if (dictionary[qty].length >= 50) {
          qtyCharacter.push(qty);
        }
      }
    }
    this.$store.dispatch("setQtyCharacterOptions", qtyCharacter);
    document.body.classList.add('dark');
  },
  computed: {
    ...commonComputed,
  },
};
</script>
